import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
function Dashboard() {
  const params = useParams();
  const workspaceid = params.workspaceid;
  const Navigate = useNavigate();

  const [MySelectedWorkspace, SetMySelectedWorkspace] = useState<any>({});
  const [MyWorkSpacesData, SetMyWorkSpacesData] = useState([]);

  useEffect(() => {
    LoadDashboardDataInit();
  }, []);

  function LoadDashboardDataInit() {
    let SelectedWorkSpace =
      AppContext.GetLocalStorageObject("SelectedWorkSpace");
      GetMyWorkSpaces();
    // if (SelectedWorkSpace && SelectedWorkSpace !== null) {
    //   GetMyWorkSpaces();
    // } else {
    //   Navigate("/workspace");
    // }
  }

  function GetMyWorkSpaces() {
    let object = {};
    axiosAuth
      .post(
        "https://authapi.manyge.com/api/MGWorkspace/GetMyWorkSpaces",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          var l_GridData = response.data.Data[0];
          AppContext.SetLocalStorageObject("WorkSpaceList", l_GridData);
          SetMyWorkSpacesData(l_GridData);
          let SelectedWorkSpaceid = "";
          for (let i = 0; i < l_GridData.length; i++) {
            if (l_GridData[i].Selected) {
              SetMySelectedWorkspace(l_GridData[i]);
              AppContext.SetLocalStorageObject("SelectedWorkSpace", l_GridData[i]);
              SelectedWorkSpaceid = l_GridData[i].Code;
            }
          }
          if (SelectedWorkSpaceid === "" && (workspaceid == null || workspaceid == undefined || workspaceid === "")) {
            if (l_GridData.length > 0) {
              SetMySelectedWorkspace(l_GridData[0]);
              SelectedWorkSpaceid = l_GridData[0].Code;
              AppContext.SetMyWorkSpaces(l_GridData[0].MGWorkspaceID);
              AppContext.SetLocalStorageObject("SelectedWorkSpace", l_GridData[0]);
            }
          }

          if (SelectedWorkSpaceid !== workspaceid) {
            Navigate("/dashboard/" + SelectedWorkSpaceid);
          }
        } else {
          Navigate("/workspace");
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  return (
    <div>
      <h1>Dashboard</h1>

      <Link  to={"/comm/" + MySelectedWorkspace.Code}>
        <div>Welcome --- {MySelectedWorkspace.Title}</div>
        <div>Work Space ID --- {MySelectedWorkspace.MGWorkspaceID}</div>
      </Link>
    </div>
  );
}

export default Dashboard;
