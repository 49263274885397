import React, { useState, useEffect } from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import { useNavigate } from "react-router-dom";
function MyWorkSpaces() {
  const [MyWorkSpacesData, SetMyWorkSpacesData] = useState([]);
  const [MySelectedWorkspace, SetMySelectedWorkspace] = useState({});

  const Navigate = useNavigate();
  useEffect(() => {
    GetMyWorkSpaces();
  }, []);

  function SetLocalStorage(p_MGWorkspaceObj:any) {
    AppContext.SetLocalStorageObject("SelectedWorkSpace", p_MGWorkspaceObj);
    AppContext.SetMyWorkSpaces(p_MGWorkspaceObj.MGWorkspaceID);

    Navigate("/dashboard/" + p_MGWorkspaceObj.Code);
  }

  function GetMyWorkSpaces() {
    let object = {};
    axiosAuth
      .post(
        "https://authapi.manyge.com/api/MGWorkspace/GetMyWorkSpaces",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          var l_GridData = response.data.Data[0];
          AppContext.SetLocalStorageObject("WorkSpaceList", l_GridData);
          SetMyWorkSpacesData(l_GridData);

          for (let i = 0; i < l_GridData.length; i++) {
            if (l_GridData[i].Selected) {
              SetMySelectedWorkspace(l_GridData[i]);
            }
          }
        } else {
          AppContext.ShowNotification("error", response.data.Message);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  return (
    <div>
      <h1>My Work Spaces</h1>

      <div>
        {MyWorkSpacesData.map((item:any) => (
          <>
            <a key={item.MGWorkspaceID} onClick={() => SetLocalStorage(item)}>
              {item.Title}
            </a>
          </>
        ))}
      </div>
    </div>
  );
}

export default MyWorkSpaces;
