import React, { useEffect, useState } from "react";
import RingLoader from "react-spinners/RingLoader";
export default function LoadingAnimation() {
  return (
    <div className="loader-overlay">
      <RingLoader
        color={"#ba71da"}
        loading={true}
        //   cssOverride={override}
        size={100}
        aria-label="Loading Spinner"
      />
    </div>
  );
}
