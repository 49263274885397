import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import AppContext from "../Context/Context";
export default function Header() {
  const [Showdropdown, SetShowdropdown] = useState(false);
  const [Refresh, SetRefresh] = useState(false);

  useEffect(() => {
    AppContext.GetJWTAndDecode();
    SetRefresh(!Refresh);
  }, []);

  const ShowHidedropdown = () => {
    SetShowdropdown(!Showdropdown);
  };

  const LogOutUser = () => {
    AppContext.SetCookie("Token", "", 0);
    AppContext.SetCookie("RefreshToken", "", 0);
    SetShowdropdown(!Showdropdown);
    AppContext.LogInUserName = "";
    window.location =
      "https://auth" + AppContext.LiveLinkPostFix + ".manyge.com/login" as any;
  };

  return (
    <>
      <header className="header">
        <div className="header--start">
          <figure className="header-start__logo">
            <img
              src="/images/logo.png"
              className="logo--dark"
              alt="logo"
              width="84px"
              height="20px"
            />
            <img
              src="/images/logolight.png"
              className="logo--light"
              alt="logo"
              width="84px"
              height="20px"
            />
          </figure>
        </div>
        <div className="header--end">
          <div className="header--end__user">
            <a className="user--img" onClick={ShowHidedropdown}>
              {/* <img
                src="/images/user.png"
                alt="user"
                width="30px"
                height="30px"
              /> */}
              <span>{AppContext.GetInitials(AppContext.LogInUserName)}</span>
            </a>

            {Showdropdown ? (
              <div className="header--end__links">
                <a onClick={LogOutUser}>Logout</a>
              </div>
            ) : (
              <span></span>
            )}
          </div>
        </div>
      </header>
      <menu className="menu">
        <div className="menu--link">
          <Link to={"/dashboard"}>
            <img
              src="/images/dashboard.png"
              alt="Dashboard"
              width="18px"
              height="16px"
            />
          </Link>
        </div>
        <div className="menu--link">
          <Link to={"/comm"}>
            <img
              src="/images/message.png"
              alt="Dashboard"
              width="16px"
              height="16px"
            />
          </Link>
        </div>
        <div className="menu--link">
          <a className="menu--link">
            <img
              src="/images/tasks.png"
              alt="Dashboard"
              width="22px"
              height="22px"
            />
          </a>
        </div>
      </menu>
    </>
  );
}
