import { Routes, Route } from "react-router-dom";
import "react-notifications/lib/notifications.css";
//import { NotificationContainer } from "react-notifications";
import React, { useEffect, useState } from "react";
import AppContext from "./components/Context/Context";
import Header from "./components/App/Header";
import Comm from "./components/Pages/Comm";
import MyWorkSpaces from "./components/Pages/MyWorkSpaces";
import Dashboard from "./components/Pages/Dashboard";
import ParentDragDrop from "./components/Pages/ParentDragDrop";
import Settings from "./components/Pages/Settings";
function App() {
  const [WhiteTheme, SetWhiteTheme] = useState(false);

  const EnableWhiteTheme = () => {
    SetWhiteTheme(!WhiteTheme);
  };

  useEffect(() => {
    if (WhiteTheme) {
      document.body.classList.add("white--theme");
    } else {
      document.body.classList.remove("white--theme");
    }
  }, [WhiteTheme]);

  useEffect(() => {
    let IsloggedIn = AppContext.IsUserLoggedIn(true);
    if (!IsloggedIn) {
      // window.location = 'https://auth'+AppContext.LiveLinkPostFix +'.manyge.com/login'
    }
  }, []);

  return (
    <main>
      {/* <NotificationContainer /> */}
      <div className="App--main">
        <a onClick={EnableWhiteTheme} className="btn--theme">
          {WhiteTheme === true && (
            <img src="/images/dark.svg" alt="img" width="20px" height="20px" />
          )}
          {WhiteTheme === false && (
            <img src="/images/light.svg" alt="img" width="20px" height="20px" />
          )}
        </a>
        <Header />
        <div className="Body--Main">
          <Routes>
            <Route path="" element={<Comm />} />
            <Route path="workspace" element={<MyWorkSpaces />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route
              path="dashboard/:workspaceid"
              element={<Dashboard />}
            />
            <Route path="comm"  element={<Comm />} />
            <Route path="comm/:workspaceid" element={<Comm />} />
            <Route
              path="comm/:workspaceid/:chennelid"
              element={<Comm />}
            />
            <Route path="testdragdrop" element={<ParentDragDrop />} />
            <Route path="settings" element={<Settings />} />
          </Routes>
        </div>
      </div>
    </main>
  );
}

export default App;
